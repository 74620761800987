.user_mode {
  .eventAggregatorElement {

    font-family: $font-tertiary;

    a {
      color: $color-gray-dk;
      img.ics_file_download {
        display: none;
      }
      &:hover {
        color: $color-primary;
        text-decoration: underline;
      }
    }

    .aggHeader {
      margin-bottom: 7px;
    }

    abbr[title] {
      text-decoration: none;
    }
    .time {
      font-weight: 700;
      margin-right: -.5em;
    }
    .location {
      font-family: $font-tertiary;
      color: $color-gray-dk;
      text-transform: none;
      font-weight: 700;
    }
    .tags {
      font-weight: 700;
    }
    .dateImage {
      background-color: $color-secondary;
      color: white;
      .date {
        font-weight: 700;
        font-size: 2.5em;
      }
      .month {
        font-weight: 700;
        color: white;
        font-size: 1em;
      }

      &:after {
        display: none;
      }
    }

  }
}

@media only screen and (max-width: 480px) {
  .user_mode {
    .news-section {
      .column-2 {
        background-color: $color-secondary;
        padding: 100px 20px 20px;
      }
      .eventAggregatorElement {
        background-color: white;
        padding: 0 20px 20px 20px;
        .aggHeader {
          height: auto;
          margin-top: -175px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .has-mobile-nav {
    .user_mode {
      .news-section {
        .column {
          padding-left: 0;
          padding-right: 30px;
        }
        .eventAggregatorElement {
          .aggHeader {
            font-size: 30px;
            line-height: 1.8;
          }
        }
      }
    }
  }
}
