.user_mode {
  .testimonial-slider {
    max-width: 100%;
    overflow: hidden;
    .pageElement {
      margin-top: 0;
    }
    padding: 0 0 40px 0;
    color: $color-gray-md;
    .text,
    .text p {
      font-family: $font-secondary;
      font-size: 18px;
      font-weight: 700;
      strong {
        font-size: 15px;
        text-transform: uppercase;
        color: $color-gray-dk;
        font-weight: 900;
      }
    }
  }
}


.has-main-nav {
  .user_mode {
    .testimonial-slider {

      padding: 0 0 50px 0;

    }
  }

}
