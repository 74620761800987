.user_mode {

	&.sportsPage {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $font-tertiary;
		}
	}
  &.teamPage {
    #sport-header {


      #snm_advert {
        .share_link {
          a {
            color: $color-secondary;
          }
        }
      }
      .sport-colors {
        padding-left: 0;
        a.team-logo-wrapper {
          display: none;
        }
      }
      .sport-tools ul {
        li {
          border-bottom: 0;
          a {
            font-family: $font-tertiary;
            color: $color-gray-md;
            font-size: 18px;
            text-shadow: none;
          }
          &.selected,
          &:hover {
            border: 0;
            background-color: $color-gray-md;
            a {
              color: white;
            }
          }
        }
      }
    }
  }
}
