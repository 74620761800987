.theme-main-nav .main-nav-item {
  display: flex;
  flex-flow: row;
  position: relative;
  height: 100%;
  order: 1;
  margin-left: auto;
  transition: margin .2s linear;
  margin-right: 0;
  .sn-social-media-list {
    a {
      background: $color-gray-md !important;
    }
    .sn-social-media-icon {
      height: 1.4em;
      width: 1.4em;
      &:before {
        font-size: .9em;
        line-height: 1.55em;
        width: 1.6em;
      }
      &:hover {
        background: $color-primary !important;
      }
    }
  }
}
