.has-main-nav {
  .user_mode {
    &.home {
      .theme-sub-nav {
        display: none;
      }
      #topNavPlaceholder {
				background: transparent;
				height:125px;
      }
    }
  }
}
