// Hide special containers depending on media size
.has-main-nav {
	.user_mode {
		.layoutContainer.mobile-only {
			display:none;
		}
	}
}

.has-mobile-nav {
	.user_mode {
		.layoutContainer.desktop-only {
			display:none;
		}
	}
}
