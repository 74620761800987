.user_mode {
  .social-section {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px;

    .column-1 {
      text-align: center;
      iframe {
        width: 100% !important;
      }
    }

    .textBlockElement {
      margin-top: 0;
      padding-top: 0;
      &>h3 {
        text-align: left !important;
        margin-bottom: -8px;
        span {
          font-family: $font-tertiary;
          font-size: 32px;
          color: white;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .user_mode {
    .social-section {
      padding-top: 100px;
      padding-bottom: 80px;
      .column {
        overflow: hidden;
      }

      .column-1 {
        iframe {
          width: 100% !important;
        }
      }

      .column-2,
      .column-3 {
        padding-top: 35px;
      }

      .textBlockElement {
        &>h3 {
          span {
            font-size: 36px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
