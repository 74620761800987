.user_mode {
  .link-button h4 {
    text-align: center;
    a {
      display: inline-block;
      font-family: $font-tertiary;
      font-size: 18px;
      line-height: 41px;
      color: $color-primary;
      border: 2px solid $color-primary;
      border-radius: 5px;
      min-width: 140px;
      text-align: center;
      padding: 0 20px;
      background: transparent;
      background-image: linear-gradient(to right, rgba(white, 0) 50%, rgba(255, 209, 0, 6) 50%);
      background-size: 200% 100%;
      background-position: center left;
      background-repeat: no-repeat;
      transition: all 0.25s ease-in;
      &:after {
        display: none;
      }
      &:hover {
        background-position: center right;
        color: white;
        transition: all 0.25s ease-in;
      }
    }
  }

}
