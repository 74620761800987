$edit-mode-mobile-color:#5c0fce;
$edit-mode-desktop-color:orange;

.edit_mode {
  .layoutContainer.mobile-only {
    outline: 1px solid $edit-mode-mobile-color;
    box-shadow: inset 0 0 0 1px $edit-mode-mobile-color;
    margin-top: 2px;
    &:before {
      content: '\f10b';
      display: block;
      font-family: "fontawesome";
      font-size: 36px;
      color: $edit-mode-mobile-color;
      position: absolute;
      height: 36px;
      width: 36px;
      top: 21px;
      left: -35px;
      padding: 0;
      line-height: 36px;
      text-align: center;
    }
  }
}

.edit_mode {
  .layoutContainer.desktop-only {
    outline: 1px solid $edit-mode-desktop-color;
    box-shadow: inset 0 0 0 1px $edit-mode-desktop-color;
    margin-top: 2px;
    &:before {
      content: '\f109';
      display: block;
      font-family: "fontawesome";
      font-size: 24px;
      color: $edit-mode-desktop-color;
      position: absolute;
      height: 36px;
      width: 36px;
      top: 21px;
      left: -35px;
      padding: 0;
      line-height: 36px;
      text-align: center;
    }
  }
}
