// Search Styles
.theme-search-box {
  // Hides Search boxes while navigation is loading
  display: none;
  .has-mobile-nav &,
  .has-main-nav & {
    display: block;
  }
}

.has-main-nav {
  .theme-search-bar {
    .theme-search-box {
      @extend %clearfix;
      ~.theme-search-box {
        margin-left: $site-search-spacing;
      }
    }

    .theme-search-input,
    .theme-search-submit,
    .theme-search-clear {
      float: left;
      height: $site-search-height;
      line-height: $site-search-height;
      transition: background $transition-linear;
      font-family: $font-tertiary;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 11px;
      text-align: right;
      text-transform: uppercase;
    }

    .theme-search-input,
    .theme-search-clear {
      font-size: $site-search-input-font-size;
    }

    .theme-search-input {
      background: $site-search-input-background;
      border: $site-search-border-width solid $site-search-input-border-color;
      border-right: none;
			padding: 0 0 0 $site-search-spacing;
			display:none;

      &::-ms-clear {
        display: none;
      }
      &:focus {
        outline: none;
        background: $site-search-input-background-focus;
      }
    }

    .theme-search-submit {
      cursor: pointer;
      color: $site-search-submit-color;
      font-size: $site-search-submit-font-size;
      border: $site-search-border-width solid $site-search-submit-border-color;
      padding: 0 $site-search-spacing;
      background: transparent;
      text-align: left;
      text-indent: -9999px;
      display: inline-block;
      position: relative;
      &:before {
        content: "\f002";
        font-family: FontAwesome;
        font-size: 16px;
        text-align: center;
        color: $color-gray-dk;
        position: absolute;
        display: block;
        text-indent: 0;
        top: -3px;
        left: 5px;
      }

      &:focus,
      &:hover {
        background: $site-search-submit-background-focus;
      }
    }

    .theme-search-clear {
      float: left;
      text-decoration: none;
      text-align: center;
      margin-left: 0 - $site-search-height - $site-search-border-width;
      margin-right: $site-search-border-width;
      width: $site-search-height;

      &:before {
        content: "";
        color: #9ca8af;
        font-family: FontAwesome;
        font-size: $site-search-input-font-size;
      }
    }
  }
}
