.user_mode {
  .cta-custom {
    padding: 0;

    .pageElement {
      margin: 0 0 1px 0;
    }
    .sn-call-to-action.sn-variation-slide-up {
      .sn-call-to-action-overlay {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.7) 100%);
        height: 100%;
      }
      .sn-call-to-action-overlay-text {
        vertical-align: bottom;
      }
      .sn-call-to-action-title {
        font-family: $font-tertiary;
        font-size: 36px;
        position: relative;
        line-height: 1.1;
      }
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.has-main-nav {
  .user_mode {
    .cta-custom {
      padding: 0;
      .pageElement {
        margin: 0 0 1px 0;
      }
      .sn-call-to-action.sn-variation-slide-up {
        padding: 0;
        box-shadow: 0 0 0 1px #fff;

        img {
          filter: grayscale(0);
          transition: filter .3s linear;
        }

        .sn-call-to-action-overlay-text {
          vertical-align: bottom;
        }
        .sn-call-to-action-title {
          font-size: 36px;
          &:after {
            content: '';
            position: absolute;
            display: block;
            height: 14px;
            width: 25%;
            bottom: -15px;
            left: 37.5%;
            background: url(../images/icons/gold-stars-2.svg) center no-repeat;
            opacity: 0;
            transition: opacity .1s ease-in-out;
          }
        }
        .sn-call-to-action-subtitle {
          font-family: $font-primary;
          font-size: 18px;
          font-weight: 900;
          transition: all .3s ease-out;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
        }
        &:hover {

          .sn-call-to-action-title {
            &:after {
              opacity: 1;
            }
          }

          .sn-call-to-action-subtitle {
            opacity: 1;
            max-height: 25px;

          }
          img {
            filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
            filter: grayscale(100%);
          }

        }

      }
    }
  }
}
