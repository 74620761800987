.edit_mode {

  .em-max-width-25 {
    .pageElement {
      padding: 0 calc((100% - 25%) / 2);
    }
  }
  .em-max-width-30 {
    .pageElement {
      padding: 0 calc((100% - 30%) / 2);
    }
	}
	.em-max-width-40 {
    .pageElement {
      padding: 0 calc((100% - 40%) / 2)
    }
	}
	.em-max-width-50 {
    .pageElement {
      padding: 0 calc((100% - 50%) / 2)
    }
	}
}
