.user_mode {
  .quick-link {
    .linkElement {
      margin-top: 3px;
      margin-bottom: 0;
      a {
        font-weight: 900;
        color: $color-gray-md;
        font-size: 18px;
        background-color: $color-gray-lt;
        padding: 15px;
        transition: padding .2s ease-in-out;
        &:after {
          display: none;
        }
        &:hover {
          color: white;
          background-color: $color-primary;
          padding-left: 20px;
        }
      }
    }
  }
}
