.user_mode {
	.sponsor-scroller-title {
    margin: 0 30px;
}
}

// Sponsor Scroller
.sponsor-scroller {
  background: #fff;
  padding: 0px 30px;
  .slick-slider {
    .slick-prev:before, .slick-next:before {
      color: $color-gray-dk;
			font-size: 48px;
			//font-weight:900;
    }
    .slick-next:before {
      content: '\f105';
      font-family: FontAwesome;
    }
    .slick-prev:before {
      content: '\f104';
      font-family: FontAwesome;
    }
    .slick-slide {
      .heroPhotoElement {
        img {
          padding: 10px;
          max-height: 100px;
          max-width: 200px;
          width: auto;
          //filter: grayscale(100);
        }
      }
      &:hover{
        // background-color: $lt_gray;
        img {
          //filter: none;
          //transition: filter .2s linear;
        }
      }
    }
    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;
    }
  }
  .column {
    padding: 0;
  }
  .edit_mode & {
    .heroPhotoElement {
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }
  .has-mobile-nav & {
    img {
      max-height: 100px;
      width: auto;
    }
  }
}
