.user_mode {
  .testimonial-title-section {
    padding: 55px 0 0 0;
    .pageElement {
      margin-bottom: 0;
      h3 {
        font-size: 36px;
        text-transform: none;
      }
    }
  }
}

.has-main-nav {
.user_mode {
  .testimonial-title-section {
    padding: 75px 0 0 0;
  }
}
}
