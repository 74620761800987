.user_mode {
  .news-section {
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    .newsAggregatorElement {
      .details {
        padding-left: 20px;
        li>h4>a {
          font-family: $font-tertiary;
          font-size: 20px;
          line-height: 1.5em;
          color: $color-gray-dk;
          &:hover {
            color: $color-primary;
            text-decoration: underline;
          }

        }
      }
    }
    .condensed .details,
    .condensed>ul {
      list-style-type: disc;
      color: $color-primary;
      font-size: 20px;
    }
    .aggHeader {
      display: block;
      max-width: 100vw;
      width: 100vw;
      height: 175px;
      margin-left: -20px;
      padding: 110px 0 0 0;
      background-size: cover;
    }

  }
  .custom-news-agg {
    .newsAggregatorElement .condensed>a,
    .newsItemHeader a,
    .expanded .newsItemHeader>a img,
    .dateAuthor,
    .newsItemElement>h4,
    .commentsAndMore,
    .teaser {
      display: none;
    }

    .item {
      padding: 15px 0;
    }

    div.item:first-of-type {
      .newsItemHeader {
        padding: 0;
        margin-top: 7px;
        &>a {
          display: block;
          position: relative;
          float: none;
          padding: 0;
          margin-bottom: 10px;
          img {
            display: block;
            width: 100%;
            height: auto;
          }

        }
        h2 {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: flex-end;
          padding: 20px;
          a {
            font-size: 18px;
            font-weight: 900;
            text-transform: none;
            line-height: 1.35;
            color: white;
            display: block;
            position: relative;
            z-index: 1;
          }
          &:after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            display: block;
            top: -1px;
            left: 0;
						//background-color: rgba(black, .4);
						background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.7) 100%);
            pointer-events: none;
            z-index: 0;
          }
        }
      }
    }

  }
  .container-bg {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .user_mode {
    .news-section {
      background-image: none !important;
      .newsAggregatorElement .aggHeader {
        padding-left: 20px;
      }
    }

  }
}




//Tablet
@media only screen and (min-width: 768px) {
  .user_mode {
    .news-section {
      padding-top: 150px;
      padding-bottom: 100px;
      .aggHeader {
        background-image: none !important;
        height: auto;
        padding-top: 0;
        margin-top: -85px;
      }
      .pageElement {
        background: white;
        margin: 15px;
        padding: 20px;
      }
      .custom-news-agg {

        div.item:first-of-type {
          .newsItemHeader {
            margin-top: 18px;
            h2 {
              padding: 30px;
              a {
                font-size: 30px;
                line-height: 1.2;
              }
            }
          }
        }
        .extendedOptions {
          margin-top: 0;
        }

      }
    }
  }
}
