.user_mode {
  .hero-video-section {
    position: relative;
    padding: 0;
    margin: 0;

    .column {
      padding: 0;
			margin: 0;
			max-height:800px;
    }
    .pageElement {
      padding: 0;
      margin: 0;
    }
    #heroVideo {
      position: relative;
      width: 100%;
      height: auto;
      max-height: 800px;
    }
    .hero-video-title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 250px;
      display: block;
      .text * {
        color: white;
      }
      h2 {
        margin-bottom: 40px;
      }
    }
    .hero-button {
      font-family: $font-tertiary;
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      padding: 10px 20px;
      text-transform: uppercase;
      background: $color-primary;
      color: white;
			border-radius: 5px;
			border: 3px solid $color-primary;
			transition: border .3s linear;
			&:hover {
				border:3px solid white;
			}
    }
  }
}
