.user_mode {
  .snFooterContainer {
    background: $color-secondary;
    .global-footer {
      padding-top: 50px;
      padding-bottom: 10px;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      color: white;
      background: transparent;
      .column {
        display: block;
        width: 100%;
        text-align: center;
      }
      .club-logo img {
        max-width: 100px;
      }
      .column-2 {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        a {
          font-family: $font-tertiary;
          font-size: 18px;
          text-transform: uppercase;
          padding: 0 25px;
          color: #fff;
          &:hover {
            color: $color-primary;
            text-decoration: underline;
          }
        }
      }
      .column-3 {
        .sn-social-media-list {
          a {
            background: $color-gray-md !important;
          }
          .sn-social-media-icon {
            height: 35px;
            width: 35px;
            &:before {
              font-size: 20px;
              line-height: 35px;
              width: 35px;
            }
            &:hover {
              background: $color-primary !important;
            }
          }
        }
      }
    }
  }
}

.has-main-nav {
  .user_mode {
    .snFooterContainer {
      .global-footer {
        .column-2 {
          flex-flow: row nowrap;
        }
      }
    }
  }
}

#siteFooter {
  background: $color-secondary;
  color: white;
  font-size: 12px;
  padding-bottom: 30px;
  &>ul li {
    font-size: 12px;
    color: white;
  }
  a,
  &>ul a {
    color: white;
  }
  #poweredByNGIN {
    a {
      img {
        opacity: 1;
      }
      &:after {
        display: none;
      }
    }
  }
}
