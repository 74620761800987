.user_mode {
  .gold-standard-section {
    position: relative;
    background: $color-secondary;
    color: white;
    padding-top: $special-container-padding-mobile;
    padding-bottom: $special-container-padding-mobile;

    .textBlockElement {
			font-size: 1.1em;
      padding-top: 25px;
      padding-left: 30px;
      padding-right: 30px;
      strong {
				font-weight: 900;
      }


      h3 {
        color: $color-primary;
        text-transform: none;
        font-size: 22px;
      }
      .leftTextImage {
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        left: 0;
        text-align: center;
      }
    }
  }
}


.has-main-nav {
  .user_mode {
    .gold-standard-section {
      padding-top: $special-container-padding-desktop;
      padding-bottom: $special-container-padding-desktop;
      .textBlockElement {
        font-size: 24px;
        h3 {
          font-size: 40px;
        }
        .leftTextImage {
          //top: -25px;
        }
      }
    }
  }
}
