// Resets some of the edit mode ui that can get overwritten by sites custom css
.edit_mode {
  .submitLightbox {
    a {
      color: #555;
    }
  }
  .pageElementMenu a {
    color: #555;
  }
  a {
    &.addPageElementTop {
      color: #555;
    }
  }
  #lightboxBody {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: black;
      text-transform: none;
    }
  }
  .nodePicker-site-name img {
    display: none !important;
  }
  h3.aggHeader,
  .textBlockElement>h3 {
    color: black;
  }
  .pageElement {
    .sn-call-to-action img {
      max-width: 100%;
    }
  }
}
