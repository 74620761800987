.user_mode {
  .mobile-hero-section {
    position: relative;
    padding: 0;
    margin-top: -40px;
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 400px;
      top: 0;
      left: 0;
      background-color: rgba(white, .8);
      z-index: 0;
    }

    .column {
      padding: 320px 0 0 0;
    }
    .pageElement {
      padding: 0;
    }

    .mobile-hero-logo {
      position: absolute;
      top: 145px;
      left: 0;
      width: 100%;
      display: block;
      height: 220px;
      z-index: 2;
      img {
        max-width: 200px;
      }
    }

    .mobile-hero-title {
      background-color: $color-secondary;
      padding: 50px 30px 50px 30px;
      position: relative;
      z-index: 1;
      .text {
        color: $color-primary;
        font-size: 32px;
        font-weight: 900;
        line-height: 1.2;
        text-align: center;
      }
    }

  }
}
